import * as React from 'react';
import * as ed from '@noble/ed25519';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import HashTable from "./HashTable";
import * as crypto from "./Crypto";
import * as utils from "./Utils";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import GitHubIcon from '@mui/icons-material/GitHub';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import StarIcon from '@mui/icons-material/Star';
import RefreshIcon from '@mui/icons-material/Refresh';
import OutputIcon from '@mui/icons-material/Output';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const formatColor = [
  (x: string) => <Typography variant="button" color="secondary.main" fontSize="large">{x}</Typography>,
  (x: string) => <Typography variant="button" color="info.main" fontSize="large">{x}</Typography>,
  (x: string) => <Typography variant="button" color="success.main" fontSize="large">{x}</Typography>,
  (x: string) => <Typography variant="button" color="error.main" fontSize="large">{x}</Typography>,
  (x: string) => <Typography variant="button" color="warning.main" fontSize="large">{x}</Typography>
];

const partyNames = [
  () => formatColor[1]("Client"),
  () => formatColor[2]("Server"),
  () => formatColor[3](""),
  () => formatColor[4]("")
];

export default function App() {
  const randomHash = () => ed.utils.bytesToHex(ed.utils.randomPrivateKey());
  const genB = (x: number) => {
    var ret = Array(x);
    for (let i = 0; i < x; i++) {
      ret[i] = randomHash();
    }
    return ret;
  };

  const [nHashes, setNHashes] = React.useState(5);
  const [genMatch, setGenMatch] = React.useState(false);
  const [X, setX] = React.useState(randomHash());
  const [B, setB] = React.useState(genB(nHashes));
  const [sk, setSk] = React.useState(crypto.ElGamal.genPrivate());
  const [pk, setPk] = React.useState(crypto.ElGamal.genPublic(sk));

  const genM = (pk: ed.RistrettoPoint, b: Array<string>) => {
    return b.map((m, i) => crypto.ElGamal.encrypt(pk, m));
  };
  const [M, setM] = React.useState(genM(pk, B));
  const [Xct, setXct] = React.useState(crypto.ElGamal.encrypt(pk, X));

  const genY = (m: Array<crypto.EGCt>, xct: crypto.EGCt) => {
    return m.map((ct, i) => crypto.ElGamal.subtract(ct, xct))
  };
  const [Y, setY] = React.useState(genY(M, Xct));

  const genR = (sk: bigint, y: Array<crypto.EGCt>) => {
    var r = y.map((ct, i) => crypto.ElGamal.decryptZero(sk, ct));
    utils.shuffleArray(r);
    return r;
  };

  const [R, setR] = React.useState(genR(sk, Y));

  const recompute = (x: string, X: Array<string>, s: bigint) => {
    var p = crypto.ElGamal.genPublic(s);
    setPk(p);
    var m = genM(p, X);
    setM(m);
    var xct = crypto.ElGamal.encrypt(p, x);
    setXct(xct);
    var y = genY(m, xct);
    setY(y);
    var r = genR(s, y);
    setR(r);
  };

  const handleParameterChange = function (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    var h = randomHash();
    setX(h);
    var H = genB(nHashes - (genMatch ? 1 : 0));
    if (genMatch) H.push(h);
    utils.shuffleArray(H);
    setB(H);
    recompute(h, H, sk);
  };

  const handleKeygen = function (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    var s = crypto.ElGamal.genPrivate();
    setSk(s);
    recompute(X, B, s);
  };

  const setupParameters = () => {
    return (
      <Paper elevation={1} sx={{ pt: 2.5, pb: 1.5, width: "50%", margin: "0 auto" }}>
        <Grid container spacing={0}>
          <Grid item xs={6} sx={{ mb: 1, paddingX: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="size-label" color="success">Server Table Size</InputLabel>
              <Select
                labelId="size"
                id="tableSize"
                value={String(nHashes)}
                label="Server Table Size"
                onChange={(event: SelectChangeEvent) => setNHashes(parseInt(event.target.value))}
                color="success"
              >
                {[5, 10, 20, 30, 40, 50].map((x, i) => <MenuItem value={x}>{x}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormGroup sx={{ margin: "0 auto" }}>
              <Button sx={{ mr: 3.5 }} variant="outlined" color="secondary"
                onClick={handleParameterChange} startIcon={<RefreshIcon />}>
                Regenerate
              </Button>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={genMatch}
                    onChange={(event) => { setGenMatch(event.target.checked); }}
                  />
                }
                label="Generate a match"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Paper>
    )
  };

  const setupRound = () => {
    return (
      <Paper elevation={1} sx={{ pt: 2, pb: 3, margin: "0 auto" }} >
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <HashTable title="X" titleColor="info.main"
              data={[crypto.truncateString(X, 64)]}
              color={["info"]} showPoints={false} suffix="" visibleTo="Client"></HashTable>
          </Grid>
          <Grid item xs={6}>
            <HashTable title="B" titleColor="success.main"
              data={B.map((x, i) => crypto.truncateString(x, 64))}
              color={Array(B.length).fill("success")} showPoints={false} suffix="" visibleTo="Server"></HashTable>
          </Grid>
        </Grid >
      </Paper >
    );
  }

  const protocolRound1 = () => {
    return (
      <Paper elevation={1} sx={{ pt: 2, pb: 3 }}>
        <Grid container spacing={0}>
          <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <FormGroup sx={{ mt: 1, ml: 2 }}>
              <LightTooltip title="Visible to: Server" placement="top">
                <TextField label="Private Key" value={crypto.truncateString(sk.toString(16), 64)} id="outlined" sx={{ width: "95%", ml: 2, mb: 2 }} InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                }} color="success" />
              </LightTooltip>
              <LightTooltip title="Visible to: Client, Server" placement="top">
                <TextField label="Public Key" value={crypto.truncateString(pk.toHex(), 64)} id="outlined" sx={{ width: "95%", ml: 2, mb: 2 }} InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                }} color="success" />
              </LightTooltip>
              <Button sx={{ width: "95%", ml: 2 }} variant="outlined" color="secondary"
                onClick={handleKeygen} startIcon={<RefreshIcon />}>Regenerate</Button>
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <HashTable title="M = encrypted(B)" titleColor="success.main"
              data={M.map((x, i) => crypto.ElGamal.format(x, 24))}
              color={Array(B.length).fill("success")} showPoints={false} suffix="" visibleTo="Client, Server"></HashTable>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const protocolRound2 = () => {
    return (
      <Paper elevation={1} sx={{ pt: 2, pb: 3 }}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <HashTable title="Encrypted(X)" titleColor="info.main"
              data={[crypto.ElGamal.format(Xct, 30)]}
              color={["info"]} showPoints={false} suffix="" visibleTo="Client"></HashTable>
          </Grid>
          <Grid item xs={6}>
            <HashTable title="Y = M - Encrypted(X)" titleColor="info.main" data={Y.map((ct, i) => crypto.ElGamal.format(ct, 30))} color={Array(nHashes).fill("info")} showPoints={false} suffix="" visibleTo="Client, Server"></HashTable>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const protocolRound3 = () => {
    const colorResult = (res: Array<boolean>) => res.map((x, i) => x ? "error" : "success");

    return (
      <Paper elevation={1} sx={{ pt: 2, pb: 3, width: "25%", margin: "0 auto" }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <HashTable title="Result" titleColor="success.main" data={R.map((x, i) => String(x).toUpperCase())} color={colorResult(R)} showPoints={false} suffix="" visibleTo="Server"></HashTable>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <Container fixed sx={{ backgroundColor: "#EFF7F6", borderRadius: "0.5em" }}>
      <Box sx={{ my: 10, flexGrow: 1 }}>
        <Typography variant="h3" gutterBottom sx={{ textAlign: "center", pt: 3, fontWeight: "bold", mb: 1 }}>
          Identifying Harmful Media in<br />End-to-End Encrypted Communication
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ textAlign: "center", pt: 0 }}>
          Efficient Private Membership Computation
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mb: 4 }}>
          <Grid container spacing={0}>
            <Grid item xs={4}></Grid>
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <Link href="https://www.usenix.org/system/files/sec21-kulshrestha.pdf" color="secondary"><Button variant="outlined" startIcon={<TextSnippetIcon sx={{ fontSize: 30 }} />} color="secondary">Paper</Button></Link>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <Link href="https://github.com/citp/pmc"><Button variant="outlined" startIcon={<GitHubIcon sx={{ fontSize: 30 }} />} color="secondary">Code</Button></Link>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Box>

        <Divider />

        <Typography variant="button" display="block" gutterBottom sx={{ mt: 2, mb: -2, fontSize: 30 }}>
          Instructions
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;This example is a gross simplification. Please refer to the <Link href="https://www.usenix.org/system/files/sec21-kulshrestha.pdf" color="secondary.main">paper</Link> for the exact protocol.
          <br />
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;There are 2 participants: {partyNames[0]()} and {partyNames[1]()}.
          <br />
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;Hover over each table to view a list of participants it is visible to.
          <br />
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;Random inputs can be generated by selecting the table size below.
        </Typography>

        {setupParameters()}

        {/* SETUP */}
        <Typography variant="button" display="block" gutterBottom sx={{ mt: 2, mb: -2, fontSize: 30 }}>
          Setup
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;{partyNames[0]()} holds a hash {formatColor[1]('X')} of some media they want to share.
          <br />
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;{partyNames[1]()}'s input table {formatColor[2]('B')} contains hashes of known CSAM.
        </Typography>

        {setupRound()}

        {/* PROTOCOL */}
        <Typography variant="button" display="block" gutterBottom sx={{ mt: 2, mb: 0, fontSize: 30 }}>
          Protocol
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 0, mb: 2 }}>
          <LooksOneIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;
          {partyNames[1]()} generates a random (private, public) key pair <VpnKeyIcon sx={{ mb: -0.25 }} fontSize="small" color="success" /> and homomorphically encrypts {formatColor[2]('B')} using the public key as {formatColor[2]('M')}. Each row of {formatColor[2]('M')} contains an ElGamal ciphertext.
        </Typography>

        {protocolRound1()}

        <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
          <LooksTwoIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;
          {partyNames[0]()} uses the public key <VpnKeyIcon sx={{ mb: -0.25 }} fontSize="small" color="success" />  to encrypt {formatColor[1]('X')} and subtracts it from each ciphertext in {formatColor[2]('M')} to generate {formatColor[1]('Y')}.
        </Typography>

        {protocolRound2()}

        <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
          <Looks3Icon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;
          {partyNames[1]()} uses the private key <VpnKeyIcon sx={{ mb: -0.25 }} fontSize="small" color="success" /> to check whether any row of {formatColor[1]('Y')} decrypts to 0.
        </Typography>

        {protocolRound3()}

        <Typography variant="h6" gutterBottom sx={{ mt: 2, mb: -1 }}>
          No participant learns any other information.
        </Typography>

        <Typography variant="h6" gutterBottom>
          &nbsp;
        </Typography>

      </Box>
    </Container >
  );
}
