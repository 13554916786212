import { hash } from "tweetnacl";
import * as ed from '@noble/ed25519';

export function mapToCurve(x: string) {
  return ed.RistrettoPoint.hashToCurve(SHA512(x));
}

export const G = mapToCurve("Generator G");

export interface BTuple {
  E: ed.RistrettoPoint,
  Ct: Uint8Array
}

export function SHA512(x: string) {
  var enc = new TextEncoder();
  return hash(enc.encode(x));
}

export function truncateString(str: string, len: number) {
  return str.slice(0, len / 2) + "..." + str.slice(str.length - (len / 2), str.length);
}

export const randomScalar = function () {
  return ed.utils.mod(BigInt("0x" + ed.utils.bytesToHex(ed.utils.randomPrivateKey())), ed.CURVE.l);
};

export const ecNegate = (x: ed.RistrettoPoint) => ed.RistrettoPoint.ZERO.subtract(x);

// export function formatECP(x: ECTuple) {
//   return truncateString(x.Q.toHex(), 12) + " & " + truncateString(x.S.toHex(), 12);
// }

// ElGamal Encryption

export interface EGCt {
  C: ed.RistrettoPoint,
  D: ed.RistrettoPoint
}

export const ElGamal = {
  // Keygen
  genPrivate: () => randomScalar(),
  genPublic: (sk: bigint) => G.multiply(sk),

  encrypt: (pk: ed.RistrettoPoint, m: string) => {
    var k = randomScalar();
    return {
      C: G.multiply(k),
      D: pk.multiply(k).add(mapToCurve(m))
    }
  },
  decryptCheck: (sk: bigint, ct: EGCt, m: string) => ct.D.subtract(ct.C.multiply(sk)).equals(mapToCurve(m)),
  decryptZero: (sk: bigint, ct: EGCt) => ct.D.equals(ct.C.multiply(sk)),

  format: (pt: EGCt, l: number) => truncateString(pt.C.toHex(), l) + ", " + truncateString(pt.D.toHex(), l),

  subtract: (a: EGCt, b: EGCt) => { return { C: a.C.subtract(b.C), D: a.D.subtract(b.D) } },
};